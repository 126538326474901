import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./navbar.css";

const CurrencyWidget = () => {
    const [exchangeRates, setExchangeRates] = useState({});
    const [loading, setLoading] = useState(true);
    const [bitcoinRates, setBitcoinRates] = useState({});
    const [goldRateTRY, setGoldRateTRY] = useState(null);
    const [exchangeChanges, setExchangeChanges] = useState({});
    const API_URL = 'https://finans.truncgil.com/today.json';
    const BITCOIN_API_URL = 'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd,eur,try';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_URL);
                const data = response.data;

                setExchangeRates({
                    USD: data.USD.Alış,
                    EUR: data.EUR.Alış,
                    GBP: data.GBP.Alış
                });

                setExchangeChanges({
                    USD: data.USD.Değişim,
                    EUR: data.EUR.Değişim,
                    GBP: data.GBP.Değişim,
                    gold: data["gram-altin"].Değişim
                });

                setGoldRateTRY(data["gram-altin"].Alış);

                const bitcoinResponse = await axios.get(BITCOIN_API_URL);
                const bitcoinData = bitcoinResponse.data.bitcoin;

                setBitcoinRates({
                    usd: bitcoinData.usd.toFixed(0),
                    eur: bitcoinData.eur.toFixed(0),
                    try: bitcoinData.try.toFixed(0)
                });

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:');
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 86400000);

        return () => clearInterval(interval);
    }, [API_URL, BITCOIN_API_URL]);

    const getChangeClass = (change) => {
        if (!change) return "";

       
        const numericChange = parseFloat(change.replace(',', '.').replace('%', ''));

        if (numericChange > 0) {
            return "positive-change"; 
        } else if (numericChange < 0) {
            return "negative-change"; 
        }
        return ""; 
    };

    return (
        <div id="carouselExampleSlidesOnly" className="vertical carousel slide currency-widget" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="4000">
                    <div className="flex justify-center items-center">
                        <div>
                            <span className="currency-badge">USD</span>
                        </div>
                        <div className="leading-4">
                            <span className="currency-value font-semibold">{exchangeRates.USD}</span><br />
                            <span className={`currency-change ${getChangeClass(exchangeChanges.USD)}`}>{exchangeChanges.USD}</span>
                        </div>
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <div className="flex justify-center items-center">
                        <div>
                            <span className="currency-badge">EUR</span>
                        </div>
                        <div className="leading-4">
                            <span className="currency-value font-semibold">{exchangeRates.EUR}</span><br />
                            <span className={`currency-change ${getChangeClass(exchangeChanges.EUR)}`}>{exchangeChanges.EUR}</span>
                        </div>
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <div className="flex justify-center items-center">
                        <div>
                            <span className="currency-badge">GBP</span>
                        </div>
                        <div className="leading-4">
                            <span className="currency-value font-semibold">{exchangeRates.GBP}</span><br />
                            <span className={`currency-change ${getChangeClass(exchangeChanges.GBP)}`}>{exchangeChanges.GBP}</span>
                        </div>
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <div className="flex justify-center items-center">
                        <div>
                            <span className="currency-badge">Gr. Altın</span>
                        </div>
                        <div className="leading-4">
                            <span className="currency-value font-semibold">{goldRateTRY}</span><br />
                            <span className={`currency-change ${getChangeClass(exchangeChanges.gold)}`}>{exchangeChanges.gold}</span>
                        </div>
                    </div>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <span className="currency-badge">BTC</span>
                    <span className="currency-value font-semibold">${bitcoinRates.usd}</span>
                </div>
            </div>
        </div>
    );
};

export default CurrencyWidget;
