import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import TitleBar from "../titleBar/TitleBar";
import SliderCard from "../sliderCard/SliderCard";
import Smallcards from "../smallcards/smallcards";
import Card from "../sliderDownCards/sdcards";
import "./bigcardlist.css"

const API_BASE_URL = "https://api.ekonomimanset.com";

const BigCardList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to remove HTML tags
  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    const fetchHotNews = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/news/hot-news`, {
          timeout: 5000,
        });

        

        if (!response.data || !Array.isArray(response.data)) {
          throw new Error("API'den geçerli veri alınamadı");
        }

        const hotNews = response.data
          .filter((post) => !post.isDraft)
          .slice(0, 3)
          .map((post) => ({
            ...post,
            newsText: stripHtmlTags(post.newsText),
          }));

        

        setPosts(hotNews);
      } catch (error) {
        console.error("Error fetching hot news:", error);
        setError(
          `${error.message}`
        );
      } finally {
        setLoading(false);
      }
    };

    fetchHotNews();
  }, []);

  const handleNewsClick = async (post) => {
    try {
      await axios.put(`${API_BASE_URL}/news/${post._id}/increment-view`);
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
    }
  };

  if (loading) return <div>Yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="col-span-1 p-[10px] lg:col-span-2 lg:p-0">
      <TitleBar title="Sıcak Haberler" />
      <div className="flex flex-col gap-3 mt-4 hotnews-content">
        {posts.length === 0 ? (
          <p>
           {" "}
            {posts.length})
          </p>
        ) : (
          posts.map((post) => (
            <div key={post._id} className="">
              <div className="flex flex-col lg:flex-row items-center gap-3 bg-[#f7f7f7] shadow-md ">
                <div className="w-full flex-1 h-64 md:h-auto relative">
                  <div className="overflow-hidden">
                    <img
                      src={post.image || "/default-image.jpg"}
                      alt={post.title}
                      className="object-cover w-full h-full transition-transform duration-200 hover:scale-110"
                    />
                  </div>
                </div>
                <div className="p-2 flex-1 text-left">
                  <div className="mb-4">
                    <span className="text-gray-600">
                      {new Date(post.updateDate).toLocaleDateString("tr-TR")} -{" "}
                    </span>
                    <span className="text-red-900 font-bold">
                      {post.categories && post.categories[0]}
                    </span>
                  </div>
                  <h1 className="font-bold text-base mb-2">{post.title}</h1>
                  <div className="text-gray-700 text-sm mb-2">
                    {post.newsText && post.newsText.length > 130
                      ? `${post.newsText.substring(0, 130)}...`
                      : post.newsText}
                  </div>
                  <Link
                    to={`/news/${post.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleNewsClick(post)}
                    className="text-gray-600 text-sm hover:text-gray-800 border-b border-gray-600 hover:border-gray-800"
                  >
                    Devamını Oku
                  </Link>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="md:grid grid-cols-2 mt-4 gap-4 slider-cards-container">
        <SliderCard />
        <Card />
      </div>

      <Smallcards />
    </div>
  );
};

export default BigCardList;
