import "./home.css";
import Carousel from "../../components/carousel/Carousel";
import NewsWebsite from "../../components/actualNews/actualNews";
import MinCards from "../../components/minCards/minCards";
import CardsAndMenu from "../../components/cardsAndMenu/CardsAndMenu";
import Ref from "../../components/ref/Ref";
import AdsMidTop from "../../components/ads/AdsMidTop";
import AdsMidBottom from "../../components/ads/AdsMidBottom";
import CarouselSecond from "../../components/carouselSecond/carouselSecond";
import AdsCenterPopup from "../../components/ads/adsCenterPopup"; // İsimlendirmeyi düzelt

const Home = () => {
  return (
    <div className="mx-[10px] md:mx-[80px] position-relative homepage-container-div">
      <Carousel />
      {/* <CarouselSecond /> */}
      <AdsMidTop />
      <AdsCenterPopup /> {/* İsimlendirmeyi düzelt */}
      <NewsWebsite />
      {/* <AdsMidBottom /> */}
      <CardsAndMenu />
      <MinCards />
      <Ref />
    </div>
  );
};

export default Home;
