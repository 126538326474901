import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./categoryPage.css";
import axios from "axios";
import SideMenu from "../../components/sideMenu/SideMenu";
import MostRead from "./MostRead";
import MostCommented from "./MostCommented";
import TitleBar from "../../components/titleBar/TitleBar";

const API_BASE_URL = "https://api.ekonomimanset.com/news";

const OrhanErturkPage = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState(18);
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const response = await fetch("https://api.ekonomimanset.com/news"); 
        const data = await response.json();

        
        

        
        if (Array.isArray(data)) {
          
          const filteredNews = data.filter(
            (item) =>
              item.categories.includes("Orhan Ertürk") && item.isDraft === false
          );
          setNewsItems(filteredNews);
        } else if (data && data.news) {
          
          const filteredNews = data.news.filter(
            (item) =>
              item.categories.includes("Orhan Ertürk") && item.isDraft === false
          );
          setNewsItems(filteredNews);
        } else {
          console.error("API yanıtı beklenen formatta değil:", data);
        }
      } catch (error) {
        console.error("Haberler çekilirken bir hata oluştu:", error);
      }
    };

    fetchNewsItems();
  }, []);

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 9);
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      // Görüntülenme sayısını artır
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

      // Habere yönlendir
      navigate(`/news/${slug}`);
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      // Hata olsa bile habere yönlendir
      navigate(`/news/${slug}`);
    }
  };

  return (
    <div className="category-page-container">
      <div className="category-page-top-container md:grid grid-cols-11 md:px-60">
        <div className="category-page-top-left-container col-span-7 md:pr-[30px]">
          <div className="category-news-grid-container">
          <TitleBar title="Orhan Ertürk Haberleri" />
          <div className="category-news-grid mt-4">
              {newsItems.slice(0, visibleItems).map((item) => (
                <div
                  key={item._id}
                  className="category-news-item cursor-pointer"
                  onClick={(e) => handleNewsClick(item._id, item.slug, e)}
                >
                  <img src={item.image} alt={item.title} />
                  <div className="category-news-content">
                    <h3 className="title">{item.title}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="text-center">
            {visibleItems < newsItems.length && (
              <button
                onClick={showMoreItems}
                className="load-more-button category-page-load-more-button "
              >
                Daha Fazla Haber Yükle
              </button>
            )}
          </div>
          <div className="lg:grid grid-cols-2 gap-4 mt-4">
            {/* <MostRead />
            <MostCommented /> */}
          </div>
        </div>
        <div className="category-page-top-right-container col-span-4 md:pl-[30px]">
          <SideMenu />
        </div>
      </div>
    </div>
  );
};

export default OrhanErturkPage;
