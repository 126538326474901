import React, { useState, useEffect } from "react";
import axios from "axios";
import "./animatedText.css";

const AnimatedText = () => {
  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    const fetchBreakingNews = async () => {
      try {
        const response = await axios.get(
          "https://api.ekonomimanset.com/news/breaking-news-animated"
        );

        // Filter news items where isDraft is false
        const filteredNews = response.data.filter((news) => !news.isDraft);

        setNewsItems(filteredNews);
      } catch (error) {
        console.error("Haberler alınırken bir hata oluştu:", error);
      }
    };

    fetchBreakingNews();
  }, []);

  const handleNewsClick = async (news) => {
    try {
      await axios.put(
        `https://api.ekonomimanset.com/news/${news._id}/increment-view`
      );
  
      window.location.href = `/news/${news.slug}`;
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      
      window.location.href = `/news/${news.slug}`;
    }
  };
  

  return (
    <div>
      <div className="run-text page">
        <div className="ticker-wrap">
          <div className="ticker-news-text">
            <span className="breaking">SON DAKİKA</span>
          </div>
          <div className="ticker">
            {newsItems.length > 0 ? (
              newsItems.map((news) => (
                <div className="ticker__item" key={news._id}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNewsClick(news);
                    }}
                  >
                    {news.title}
                  </a>
                </div>
              ))
            ) : (
              <div className="ticker__item">
                <a href="#">Yükleniyor...</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AnimatedText;
