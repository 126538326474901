import React, { useState, useEffect } from "react";
import "./adsDownPopup.css";

const AdsDownPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ad, setAd] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetch(`https://api.ekonomimanset.com/api/ads`)
      .then((response) => response.json())
      .then((data) => {
        const topAd = data.find(
          (ad) => ad.title.includes("DOWN") && ad.isActive == true
        );
        setAd(topAd);
      })
      .catch((error) => {
        console.error("Error fetching addownpop data:", error);
      });
  }, []);

  if (!ad) {
    return null; // Or a loading spinner
  }

  const handleClick = async () => {
    if (ad && ad._id) {
      try {
        await fetch(`https://api.ekonomimanset.com/api/ads/${ad._id}/hit`, {
          method: "POST",
        });
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const isIframe =
    ad.imageURL.includes(".html") || ad.imageURL.includes("sadbundle");

  const renderAdContent = () => {
    if (isIframe) {
      return (
        <iframe
          src={ad.imageURL}
          title={ad.title}
          frameBorder="0"
          allowFullScreen
          onClick={handleClick}
          style={{ width: "100%", height: "250px" }}
        ></iframe>
      );
    } else {
      return (
        <a
          href={ad.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          <img src={ad.imageURL} alt={ad.title} />
        </a>
      );
    }
  };

  return (
    <div className={`popUpAds ${isOpen ? "open" : "closed"}`}>
      <button className="toggleButton" onClick={togglePopup}>
        {isOpen ? (
          <span class="material-symbols-outlined">keyboard_arrow_down</span>
        ) : (
          <span class="material-symbols-outlined">keyboard_arrow_up</span>
        )}
      </button>
      {isOpen && <div className="adContent">{renderAdContent()}</div>}
    </div>
  );
};

export default AdsDownPopup;
