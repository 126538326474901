import React, { useState, useEffect } from "react";
import axios from "axios";
import "./trendnews.css";
import TitleBar from "../titleBar/TitleBar";

const trendingItems = [
  "Seyahat Döneminde Bütçe Dostu Seyahatin 7 Püf Noktası",
  "Kültürel Yatırımların Ekonomik Getirileri",
  "Yolculukta Tasarruf: Uzmanların Önerileri",
  "Küresel Pazarın Dönüşümü: Kültürel Çözümlerin Yeni Yolu",
  "Patronunuzun İş Beklentileri: Kriz Yönetimi ve Maliyet Optimizasyonu",
];

const LeagueTable = () => {
  const teams = [
    {
      position: 1,
      name: "Galatasaray",
      played: 38,
      goalDifference: 66,
      points: 102,
    },
    {
      position: 2,
      name: "Fenerbahçe",
      played: 38,
      goalDifference: 68,
      points: 99,
    },
    {
      position: 3,
      name: "Trabzonspor",
      played: 38,
      goalDifference: 19,
      points: 67,
    },
    {
      position: 4,
      name: "Başakşehir",
      played: 38,
      goalDifference: 14,
      points: 61,
    },
    {
      position: 5,
      name: "Kasımpaşa",
      played: 38,
      goalDifference: -3,
      points: 56,
    },
  ];

  return (
    <div className="league-table">
      <TitleBar title="Puan Durumu" />
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>O</th>
            <th>G</th>
            <th>P</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.position}>
              <td>{team.position}</td>
              <td>{team.name}</td>
              <td>{team.played}</td>
              <td>
                {team.goalDifference > 0
                  ? `+${team.goalDifference}`
                  : team.goalDifference}
              </td>
              <td>{team.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AdContainer = ({ ad, handleAdClick }) => {
  const adRef = React.useRef(null);

  React.useEffect(() => {
    adRef.current.innerHTML = '';

    if (ad.imageURL.includes('.html') || ad.imageURL.includes('sadbundle') || ad.imageURL.includes('doubleclick.net')) {
      const iframe = document.createElement('iframe');
      iframe.src = ad.imageURL;
      iframe.frameBorder = '0';
      iframe.allowFullscreen = true;
      iframe.style = 'width: auto; height: 300px; border: 0; overflow: hidden; margin-left: 23px;';
      iframe.scrolling = 'no';
      
      // Set a default height, which will be overridden if the ad specifies its own height
      iframe.height = '300';
      
      // Listen for messages from the iframe to adjust its height
      window.addEventListener('message', function(e) {
        if (e.data && e.data.type === 'setAdHeight') {
          iframe.height = e.data.height + 'px';
        }
      }, false);

      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement('img');
      img.src = ad.imageURL;
      img.alt = ad.title;
      img.className = "league-table-ads";
      img.style = 'width: auto; height: auto; object-fit: contain;';
      adRef.current.appendChild(img);
    }
  }, [ad]);

  const handleClick = async (e) => {
    e.preventDefault();
    await handleAdClick(ad._id);
    window.location.href = ad.link; // Redirect in the same tab
  };

  return (
    <div className="ad-containerx" onClick={handleClick}>
      <div ref={adRef}></div>
    </div>
  );
};

const Trending = () => {
  const [showTable, setShowTable] = useState(true);
  const [sideMenu1, setSideMenu1] = useState(null);
  const [sideMenu2, setSideMenu2] = useState(null);
  const [sideMenu3, setSideMenu3] = useState(null);
  const [sideMenu4, setSideMenu4] = useState(null);
  const [highlightedNews, setHighlightedNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tableResponse, adsResponse, newsResponse] = await Promise.all([
          axios.get("https://api.ekonomimanset.com/api/puan-durumu"),
          axios.get("https://api.ekonomimanset.com/api/ads"),
          axios.get("https://api.ekonomimanset.com/news/by-views")
        ]);
  
        setShowTable(tableResponse.data.show);
  
        const ads = adsResponse.data;
        // Sadece aktif olan reklamları filtreleyerek side menu reklamlarını set ediyoruz
        setSideMenu1(ads.find((ad) => ad.title === "Sidemenü Reklam 1" && ad.isActive === true) || null);
        setSideMenu2(ads.find((ad) => ad.title === "Sidemenü Reklam 2" && ad.isActive === true) || null);
        setSideMenu3(ads.find((ad) => ad.title === "Sidemenü Reklam 3" && ad.isActive === true) || null);
        setSideMenu4(ads.find((ad) => ad.title === "Sidemenü Reklam 4 - PUAN DURUMU YERİNE" && ad.isActive === true) || null);
  
        const newsData = newsResponse.data.news || [];
  
        const filteredHighlightedNews = newsData
          .filter((news) => !news.isDraft)
          .slice(0, 6);
        setHighlightedNews(filteredHighlightedNews);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };
  
    fetchData();
  }, []);
  

  const handleAdClick = async (adId) => {
    try {
      await axios.post(`https://api.ekonomimanset.com/api/ads/${adId}/hit`);
    } catch (error) {
      console.error("Hit count artırma hatası:", error);
    }
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      await axios.put(`https://api.ekonomimanset.com/${newsId}/increment-view`);
      window.location.href = `/news/${slug}`; // Aynı sekmede yönlendirme
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      window.location.href = `/news/${slug}`; // Hata olsa bile aynı sekmede yönlendirme
    }
  };

  return (
    <div className="trends">
      <div className="trending">
        <TitleBar title="Popüler Haberler" />
        <ul>
          {highlightedNews.map((item) => (
            <li key={item._id}>
              <div
                className="cursor-pointer"
                onClick={(e) => handleNewsClick(item._id, item.slug, e)}
              >
                <span className="text">{item.title}</span>
                <span style={{ marginLeft: "5px" }} className="arrow"> &gt; </span>
              </div>
            </li>
          ))}
        </ul>
        <br />
        <br />
        <div className="ads-trends">
          {sideMenu1 && <AdContainer ad={sideMenu1} handleAdClick={handleAdClick} />}
          <br />
          {sideMenu2 && <AdContainer ad={sideMenu2} handleAdClick={handleAdClick} />}
          <br />
          {sideMenu3 && <AdContainer ad={sideMenu3} handleAdClick={handleAdClick} />}
          <br />
        </div>
        {showTable ? (
          <LeagueTable />
        ) : (
          <div className="league-table">
            {sideMenu4 && <AdContainer ad={sideMenu4} handleAdClick={handleAdClick} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Trending;