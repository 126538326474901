import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./actualNews.css";

const API_BASE_URL = "https://api.ekonomimanset.com/news";

const NewsPage = () => {
  const [articles, setArticles] = useState([]);
  const [activeCategory, setActiveCategory] = useState("all");
  const [currentPage, setCurrentPage] = useState(0);
  const [fade, setFade] = useState(true);
  const [featuredArticle, setFeaturedArticle] = useState({
    title: "",
    category: "",
    image: "",
  });
  const itemsPerPage = 5;
  const navigate = useNavigate();

  const tags = [
    { name: "Tümü", id: "all" },
    { name: "Gündem", id: "Gündem" },
    { name: "Dünya", id: "Dünya" },
    { name: "Özel Haber", id: "Özel Haber" },
    { name: "Finans & Borsa", id: "Finans & Borsa" },
    { name: "Teknoloji", id: "Teknoloji" },
    { name: "Kripto Para", id: "Kripto Para" },
    { name: "Foto Galeri", id: "Foto-galeri" }
  ];

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(API_BASE_URL);
        if (Array.isArray(response.data.news)) {
          setArticles(response.data.news);
          // Set the initial featured article
          if (response.data.news.length > 0) {
            setFeaturedArticle(response.data.news[0]);
          }
        } else {
          console.error("API'den dönen veri dizi değil:", response.data);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const filteredArticles = activeCategory === "all"
      ? articles
      : articles.filter((article) =>
          article.categories.includes(activeCategory)
        );

    // Set featured article only if it's not a draft
    if (filteredArticles.length > 0) {
      const publishedArticles = filteredArticles.filter(article => !article.isDraft);
      if (publishedArticles.length > 0) {
        setFeaturedArticle(publishedArticles[0]);
      }
    }
  }, [activeCategory, articles]);

  const handlePrev = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage > 0
          ? prevPage - 1
          : Math.ceil(filteredArticles.length / itemsPerPage) - 1
      );
      setFade(true);
    }, 500);
  };

  const handleNext = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage < Math.ceil(filteredArticles.length / itemsPerPage) - 1
          ? prevPage + 1
          : 0
      );
      setFade(true);
    }, 500);
  };

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    setCurrentPage(0);
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      // Increment view count
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

      // Navigate to news article
      navigate(`/news/${slug}`);
    } catch (error) {
      console.error("Error incrementing view count:", error);
      // Navigate to news article even if there's an error
      navigate(`/news/${slug}`);
    }
  };

  const filteredArticles = activeCategory === "all"
    ? articles
    : articles.filter((article) =>
        article.categories.includes(activeCategory)
      );

  // Filter out drafts for pagination
  const publishedArticles = filteredArticles.filter(article => !article.isDraft);

  const startIndex = currentPage * itemsPerPage;
  const currentArticles = publishedArticles.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div className="news-page mt-4">
      <div className="header">
        <div className="dont-miss">KATEGORİLER</div>
        <nav className="categories-nav-list">
          {tags.map((category) => (
            <button
              key={category.id}
              className={category.id === activeCategory ? "active" : ""}
              onClick={() => handleCategoryClick(category.id)}
            >
              {category.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="content lg:grid grid-cols-2">
        <div
          className="featured-article cursor-pointer"
          style={{
            backgroundImage: `url(${featuredArticle.image})`,
          }}
          onClick={(e) => handleNewsClick(featuredArticle._id, featuredArticle.slug, e)}
        >
          {/* <div className="featured-title">{featuredArticle.title}</div> */}
        </div>
        <div className={`article-list ${fade ? "fade-in" : "fade-out"}`}>
          <div className="row h-full">
            <div className="col-md-8 articles-content">
              {currentArticles.map((article, index) => (
                <div
                  key={index}
                  className="article cursor-pointer"
                  onClick={(e) => {
                    handleNewsClick(article._id, article.slug, e);
                  }}
                >
                  <img
                    src={article.image}
                    alt={article.title}
                    className="article-thumbnail"
                  />
                  <div className="article-info">
                    <h3>{article.title}</h3>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-2 m-auto">
              <div className="navigation navigation-desktop">
                <span className="nav-button-left" onClick={handlePrev}>
                <span class="material-symbols-outlined">
keyboard_arrow_up
</span>
                </span>
                <span className="nav-button-right" onClick={handleNext}>
                <span class="material-symbols-outlined">
keyboard_arrow_down
</span>
                </span>
              </div>
              <div className="navigation navigation-mobile">
                <span className="nav-button-left" onClick={handlePrev}>
                  <span class="material-symbols-outlined">
chevron_left
</span>
                </span>
                <span className="nav-button-right" onClick={handleNext}>
                  <span class="material-symbols-outlined">
chevron_right
</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
