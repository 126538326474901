import React, { useState, useEffect } from "react";
import "./sdcards.css";
import axios from "axios";

const EXCLUSIVE_NEWS_URL = "https://api.ekonomimanset.com/news/exclusive-news";
const API_BASE_URL = "https://api.ekonomimanset.com/news";

const Card = () => {
  const [exclusiveNews, setExclusiveNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(EXCLUSIVE_NEWS_URL);
        const data = await response.json();

        const exclusive = data
          .filter((news) => news.isDraft === false)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);

        setExclusiveNews(exclusive);
        setLoading(false);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % exclusiveNews.length);
  //     setLoading(true);
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [exclusiveNews]);

  // const handleMouseEnter = (index) => {
  //   setCurrentIndex(index);
  //   setLoading(true);
  // };

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      // Görüntülenme sayısını artır
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

      // Haberi aynı sekmede aç
      window.location.href = `/news/${slug}`;
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      // Hata olsa bile haberi aynı sekmede açmaya devam et
      window.location.href = `/news/${slug}`;
    }
  };

  const currentNews = exclusiveNews[currentIndex];

  return (
    <div id="sdcrs" className="main-card">
      <div className="image-container">
        {loading && <div className="loading">Yükleniyor...</div>}
        <img
          src={currentNews?.image}
          alt="Main"
          onLoad={handleImageLoad}
          style={{ display: loading ? "none" : "block" }}
        />
        <div className="category">
          {currentNews?.categories &&
            currentNews.categories.map((cat, index) => (
              <button key={index} className="category-btn">
                {cat}
              </button>
            ))}
        </div>
        <h2>{currentNews?.title}</h2>
        {/* <p>
          {currentNews?.updateDate} / {currentNews?.author} /{" "}
          {currentNews?.commentCount} Yorum
        </p> */}
      </div>
      <div className="sub-cards">
        {exclusiveNews.map((news, index) => (
          <div
            key={index}
            className={`sub-card ${
              index === currentIndex ? "active" : ""
            } cursor-pointer`}
            // onMouseEnter={() => handleMouseEnter(index)}
            onClick={(e) => handleNewsClick(news._id, news.slug, e)}
          >
            <img src={news.image} alt="Sub" />
            <div className="sub-card-text">
              <h3 style={{ textAlign: "left" }}>{news.title}</h3>
              <p style={{ textAlign: "left", fontSize: 11 }}>
                {/* {news.updateDate} / {news.commentCount} Yorum */}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
